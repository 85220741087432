<script setup>
import { ref } from 'vue';

const email = ref('');
const success = ref(false);

const subscribe = async () => {
  const resp = await fetch('/api/email/cta', {
    method: 'POST',
    body: JSON.stringify({ email: email.value }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = await resp.json();

  //   console.log(data);

  success.value = data?.success;

  if (success.value) {
    email.value = '';
  }
};
</script>

<template>
  <section class="">
    <div class="container max-w-screen-xl">
      <div class="mx-auto rounded-2xl bg-base-900 px-6 py-6 lg:px-12 lg:py-12">
        <div class="grid items-center gap-6 md:grid-cols-2">
          <div class="w-full">
            <p class="font-heading text-2xl font-bold text-white md:text-3xl">Be the first to know about new logos!</p>
            <p class="mt-1 text-slate-200">Subscribe to get notified when new logos hit the store.</p>
          </div>

          <div class="w-full">
            <div class="relative w-full">
              <div class="flex flex-col items-center gap-3 lg:flex-row lg:gap-4">
                <div class="w-full">
                  <label
                    for="subscribe"
                    class="sr-only"
                    >Subscribe</label
                  >
                  <input
                    type="email"
                    id="newsletter"
                    name="email"
                    v-model="email"
                    class="block w-full rounded-lg border border-base-950 bg-base-950 px-4 py-3 text-lg text-white focus:border-purple-400 focus:ring-purple-500 disabled:pointer-events-none disabled:opacity-50"
                    placeholder="Enter your email" />
                </div>
                <button
                  type="submit"
                  @click="subscribe"
                  class="z-10 inline-flex w-full cursor-pointer items-center justify-center rounded-lg border border-purple-500 bg-purple-500 py-3 text-lg font-bold text-white duration-200 ease-in-out hover:border-purple-600 hover:bg-purple-600 lg:w-48">
                  Subscribe
                </button>
              </div>

              <p
                v-if="success"
                class="mt-4 text-center text-sm text-green-500 md:absolute md:-bottom-8 md:text-left">
                Thanks for subscribing!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
